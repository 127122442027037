/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*/
/* * {
    outline: 1px solid red;
   }
 */
.gi-section-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    scroll-snap-align: start;
}

.gi-container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
}

.pzero {
    background: rgb(188, 228, 43); 
}
.pone {
    background: radial-gradient(#e5e5e5, #ffff, #e5e5e5); 
}
.ptwo {
    background: rgb(177, 34, 82); 
}
.pthree {
    background: rgb(141, 148, 138); 
}
.pfour {
    background: rgb(202, 209, 173); 
}
