#prof-images {
  margin-left: 1px;
}

#prof-pagecontainer {
  max-width: 100vw;
  overflow: hidden; /* Hide scrollbars */
}

.prof-viewmg {
  padding: 6em;
}

#prof-rcorners-img {
  border-radius: 120px;
  border: 2px solid #73AD21;
  padding: 9px;
  width: 240px;
  height: auto;
}