
.messenger-panel-show {
  display: grid;
  width: 100%;
  height: 100vh;
/*   height: calc(100%-20px);
 */  background: #eeeef1;
 
  grid-template-columns: 388px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.messenger-panel-hide {
  display: grid;
  width: 100vw;
  height: 100vh;
/*   height: calc(100%-20px);
 */  background: #eeeef1;
 
  grid-template-columns: 50px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  height: calc(100vh - 10em);
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}