nav {
  background-color: blue;
  height: 30px;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
}

.active {
  visibility: visible;
  transition: all 0.5s;
}
.hidden {
  visibility: hidden;
  transition: all 0.5s;
  transform: translateY(-100%);
}

#nav-header { /* replace with . for class to show difference */
  background-color:#f3f3f3;
  display: flex;
  align-items: baseline;
  padding: .5rem;
  gap: 1rem;
}

.link {
  background: none;
  border: none;
  text-decoration: none;
  color: #777;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
}

.dropdown.active > .link,  /* replaced :focus with .active*/
.link:hover {
  color: black;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  display: block !important;
  left: 0;
  top: calc(100% + .25rem);
  background-color: white;
  padding: 30px;
  border-radius: .25rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .3);
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 1950ms ease-in-out, transform 1950ms ease-in-out;
}

.dropdown.active > .link + .dropdown-menu {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

/* https://www.youtube.com/watch?v=S-VeYcOCFZw */
.information-grid {
  display: grid !important;
  padding: 12px !important;
  grid-template-columns: repeat(2, max-content);
  gap: 2rem;
}

.dropdown-links {
  display: flex;
  flex-direction: column;
  gap: .25rem;
}