.conversation-list {
  display: flex;
  flex-direction: column;
}

.wrapper {
  position: relative;
  display: inline-block;
}
.xclose {
  position: absolute;
  bottom: 0;
  right: 10px;
  cursor: pointer;
}
.xdelete {
  position: absolute;
  bottom: 0;
  left: 10px;
  cursor: pointer;
}
.xassign {
  position: absolute;
  bottom: 0;
  left: 10px;
  cursor: pointer;
}