#townhall-center {
  height: 90vh;
}

#filescreen, #filemodal {
  display: none;
  position: fixed;
  overflow-y: auto;
}

#filescreen {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.25);
  z-index: 1;
}

#filemodal {
  overflow-x: hidden;
  width: 396px;
  top: 16%;
  max-height: 80vh;
  margin: 0 calc(1%);
  background-color:rgba(168, 129, 93, 0.8);
  z-index: 2;
  font-size: 16px;
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.15);
  -moz-box-shadow: 0 0 20px rgba(0,0,0,0.15);
  box-shadow: 0 0 20px rgba(0,0,0,0.15);
  border: 10px solid rgba(0,0,0,0.25);
  border-radius: 18px;
}

.disable-Selection {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}

#my-file-div {
  display: none;
  position: absolute;
  overflow-x: hidden;

  resize: both;

  width: 420px;
  top: 10%;
  max-height: 80vh;

  z-index: 9;
  background-color:rgba(168, 129, 93, 0.8);
  text-align: center;
  font-size: 16px;
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.15);
  -moz-box-shadow: 0 0 20px rgba(0,0,0,0.15);
  box-shadow: 0 0 20px rgba(0,0,0,0.15);
  border: 10px solid rgba(0,0,0,0.25);
  border-radius: 18px;
}
#my-file-div-test {
  display: none;
  position: absolute;
  overflow-x: hidden;

  resize: both;
  
  width: 420px;
  top: 10%;
  max-height: 80vh;

  z-index: 9;
  background-color:rgba(168, 129, 93, 0.8);
  text-align: center;
  font-size: 16px;
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.15);
  -moz-box-shadow: 0 0 20px rgba(0,0,0,0.15);
  box-shadow: 0 0 20px rgba(0,0,0,0.15);
  border: 10px solid rgba(0,0,0,0.25);
  border-radius: 18px;
}

.my-file-div-cloud {
    padding-left: 20px;
    margin-top: 10px;
    color: #fff;
    font-size: 25px;
}
input, select, textarea {
    font-size: 25px;
}   
.my-file-div-listing {
    overflow-y: scroll;
}
.my-file-div-delete {
    background: rgb(112, 32, 32);
    margin-left: 22px;
    border: none;
    color: #fff;
    font-size: 17px;
    border-radius: 13px;
    cursor: pointer;
}
#my-file-div-close1 {
  float: right;
  cursor: pointer;
  background: #555;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 13px;
  padding: 6px;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 16px;
}
#my-file-div-new1 {
  float: left;
  cursor: pointer;
  background: #555;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
  margin-top: 8px;
  border-radius: 13px;
  padding: 6px;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 16px;
}
#my-file-div-prev-notes1 {
  float: right;
  cursor: pointer;
  background: #555;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
  margin-top: 8px;
  border-radius: 13px;
  padding: 6px;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 16px;
}
#my-file-divheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196F3;
  color: #fff;
}
#my-file-div-title {
  padding: 10px;
  z-index: 10;
  color: #fff;
}
#my-file-div-title p {
  padding: 10px;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  color: blue;
  font-size: 20px;
}
#proto-card {
  display: none;
}

/* // https://stackoverflow.com/questions/20783975/make-span-visible-on-hover-css/20784195
 */

#myChart{
  background-color: black;
}
#tooltip {
  display: none;
  position: relative;
  top: 8px; 
  left: 10px;
  padding: 5px; 
  margin: 10px;
  z-index: 100;
  background: rgb(224, 187, 187);
  border: 1px solid #c0c0c0;
  opacity: 0.8; 
  width: 300px;
  color: black;
  text-align: left;
  
}

#graphs:hover #tooltip{
   display: block;   
}

#outPopUp:hover #tooltip{
  display: block;   
}

#graphs{
background-color: red;   
}

#outPopUp {
  position: absolute;
  width: 350px;
  height: 200px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
  background: red;
}
#outer-section {
  height:300px;
}
#code-input-str {
  display: inline-block;
  margin-top: 8px;
  font-size: 98%;
  height: 40px;
}
#code-input {
  margin-top: 14px;
  font-size: 95%;
  min-width:130px;
  max-width:220px;
}
#code-input2 {
  margin-top: 1px;
  font-size: 95%;
}

#one-span {
  display: inline-block;
}


#notes-btn1 {
  float: right;
}


#container {
  max-width: 96vw;
  padding: 0;
  float: left;
}

#status-msg {
  position: absolute;
  padding-top: 7px;
  top: 0;
}
@media only screen and (max-width: 1000px) {
  #status-msg {
    display: none;
  }
}

#acct {
  position: absolute;
  right: 0;
  padding-top: 7px;
  top: 0;
}


#move-container {
  display: flex;
}

#move-container > div {
  border: 1px solid gray;
  padding: 1em;

  height: 10em;
  width: 50%;
}

#movable-element {
  border: 1px solid green;
  background-color: #00ff0033;
  height: 100%;
  width: 100%;
}