@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.showcase header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 1000;
}

header .logo
{
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  background: url(/static/media/menu.45ba3a6b.png);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
}
.toggle.active {
  background: url(https://i.ibb.co/rt3HybH/close.png); 
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
  cursor: pointer;
}

.showcase {
  position: absolute;
  right: 0;
  width: 100%;
  min-height: 100vh;
  padding: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background: #111;
  color: #fff;
  transition: 0.5s;
  z-index: 2;
}

.menu {
  display: none;
}

.showcase.active
{
  right: 300px;
}

.showcase video
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.overlay
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #03a9f4;
  mix-blend-mode: overlay;
}

.text
{
  position: relative;
  z-index: 10;
}


.text h2
{
  font-size: 5em;
  font-weight: 800;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}
.text h3
{
  font-size: 4em;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text p
{
  font-size: 1.1em;
  color: #fff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}
.text a
{
  display: inline-block;
  font-size: 1em;
  background: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  margin-top: 10px;
  color: #111;
  letter-spacing: 2px;
  transition: 0.2s;
}

.text a:hover
{
  letter-spacing: 6px;
}
.social
{
  position: absolute;
  z-index: 10;
  bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.social li
{
  list-style: none;
}
.social li a
{
  display: inline-block;
  margin-right: 20px;
  -webkit-filter: invert(1);
          filter: invert(1);
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  transition: 0.5s;
}
.social li a:hover
{
  -webkit-transform: scale(0.5) translateY(-15px);
          transform: scale(0.5) translateY(-15px);
}
.menu
{
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.menu ul
{
  position: relative;
}
.menu ul li
{
  list-style: none;
}
.menu ul li a
{
  text-decoration: none;
  font-size: 24px;
  color: #111;
}
.menu ul li a:hover
{
  color: #03a9f4; 
}

@media (max-width: 991px)
{
  .showcase,
  .showcase header
  {
    padding: 40px;
  }
  .text h2
  {
    font-size: 3em;
  }
  .text h3
  {
    font-size: 2em;
  }
}

nav {
  background-color: blue;
  height: 30px;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
}

.active {
  visibility: visible;
  transition: all 0.5s;
}
.hidden {
  visibility: hidden;
  transition: all 0.5s;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

#nav-header { /* replace with . for class to show difference */
  background-color:#f3f3f3;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  padding: .5rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.link {
  background: none;
  border: none;
  text-decoration: none;
  color: #777;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
}

.dropdown.active > .link,  /* replaced :focus with .active*/
.link:hover {
  color: black;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  display: block !important;
  left: 0;
  top: calc(100% + .25rem);
  background-color: white;
  padding: 30px;
  border-radius: .25rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .3);
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: opacity 1950ms ease-in-out, -webkit-transform 1950ms ease-in-out;
  transition: opacity 1950ms ease-in-out, transform 1950ms ease-in-out;
  transition: opacity 1950ms ease-in-out, transform 1950ms ease-in-out, -webkit-transform 1950ms ease-in-out;
}

.dropdown.active > .link + .dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  pointer-events: auto;
}

/* https://www.youtube.com/watch?v=S-VeYcOCFZw */
.information-grid {
  display: grid !important;
  padding: 12px !important;
  grid-template-columns: repeat(2, -webkit-max-content);
  grid-template-columns: repeat(2, max-content);
  grid-gap: 2rem;
  gap: 2rem;
}

.dropdown-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: .25rem;
  gap: .25rem;
}
.card {
    width: 18rem;
}

.card:hover {
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

.card-body {
    padding: 3rem 0 !important;
}

.card-text {
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}

.container-fluid .row {
    padding-top: 6rem;
}

.overflow {
    overflow: hidden;
}
.card-img-top {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.card-img-top:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
}

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*/
/* * {
    outline: 1px solid red;
   }
 */
.gi-section-content {
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    scroll-snap-align: start;
}

.gi-container {
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
}

.pzero {
    background: rgb(188, 228, 43); 
}
.pone {
    background: radial-gradient(#e5e5e5, #ffff, #e5e5e5); 
}
.ptwo {
    background: rgb(177, 34, 82); 
}
.pthree {
    background: rgb(141, 148, 138); 
}
.pfour {
    background: rgb(202, 209, 173); 
}

.title{
	text-align: center;
	padding-top: 50px;
}

section{
	padding-top: 50px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
}
section>p{
	text-align: justify;
}

.one{
	background: #ec9c04;
}
.custom-modal-style {
  width: 1897px;
  max-width: none;
  height: 802px;
}

#cancelButton {
  float: right;
}
/* body {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  background-color: #FFFCFF;
}
 *//* Simply added the . to class rather than just button ;)  */
.button {
  margin-right: 2.5px;
  margin-left: 2.5px;
  width: 75px;
  cursor: pointer;
  box-shadow: 0px 0px 2px gray;
  border: none;
  outline: none;
  padding: 5px;
  border-radius: 5px;
  color: white;
}

#header {
  padding: 10px;
  color: #d36c6c;
  font-size: 26px;
  font-family: sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
#header button {
  background-color:#92a1d1;
}
#container {
  width: 770px;
}
#weekdays {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  color: #247BA0;
}
#weekdays div {
  width: 100px;
  padding: 10px;
}
#calendar {
  width: 100%;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.day {
  width: 100px;
  padding: 10px;
  height: 100px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  margin: 5px;
  box-shadow: 0px 0px 3px #CBD4C2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.day:hover {
  background-color: #e8faed;
}

.day + .currentDay {
  background-color:#e8f4fa;
}
.weekend {
  background-color:#d7e3e9;
}
.reservecheckbox {
  margin: 5px;
}

.event {
  font-size: 10px;
  padding: 3px;
  background-color: #58bae4;
  color: white;
  border-radius: 5px;
  max-height: 55px;
  overflow: hidden;
}
.padding {
  cursor: default !important;
  background-color: #FFFCFF !important;
  box-shadow: none !important;
}
#newEventModal, #deleteEventModal {
  z-index: 20;
  padding: 25px;
  background-color: #e8f4fa;
  box-shadow: 0px 0px 3px black;
  border-radius: 5px;
  width: 350px;
  top: 100px;
  left: calc(50% - 175px);
  position: absolute;
  font-family: sans-serif;
}
#eventTitleInput {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
  border-radius: 3px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 3px gray;
}
#eventTitleInput.error {
  border: 2px solid red;
}
#cancelButton, #deleteButton {
  background-color: #d36c6c;
}
#saveButton, #closeButton {
  background-color: #92a1d1;
}
#eventText {
  font-size: 14px;
}
#modalBackDrop {
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0,0,0,0.8);
}

/* body {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  background-color: #FFFCFF;
}
 *//* Simply added the . to class rather than just button ;)  */
.button {
  margin-right: 2.5px;
  margin-left: 2.5px;
  width: 75px;
  cursor: pointer;
  box-shadow: 0px 0px 2px gray;
  border: none;
  outline: none;
  padding: 5px;
  border-radius: 5px;
  color: white;
}

#header {
  padding: 10px;
  color: #d36c6c;
  font-size: 26px;
  font-family: sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
#header button {
  background-color:#92a1d1;
}
#container {
  width: 770px;
}
#weekdays {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  color: #247BA0;
}
#weekdays div {
  width: 100px;
  padding: 10px;
}
#calendar {
  width: 100%;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.day {
  width: 100px;
  padding: 10px;
  height: 100px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  margin: 5px;
  box-shadow: 0px 0px 3px #CBD4C2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.day:hover {
  background-color: #e8faed;
}

.day + .currentDay {
  background-color:#e8f4fa;
}
.weekend {
  background-color:#d7e3e9;
}
.reservecheckbox {
  margin: 5px;
}

.event {
  font-size: 10px;
  padding: 3px;
  background-color: #58bae4;
  color: white;
  border-radius: 5px;
  max-height: 55px;
  overflow: hidden;
}
.padding {
  cursor: default !important;
  background-color: #FFFCFF !important;
  box-shadow: none !important;
}
#newEventModal, #deleteEventModal {
  z-index: 20;
  padding: 25px;
  background-color: #e8f4fa;
  box-shadow: 0px 0px 3px black;
  border-radius: 5px;
  width: 350px;
  top: 100px;
  left: calc(50% - 175px);
  position: absolute;
  font-family: sans-serif;
}
#eventTitleInput {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
  border-radius: 3px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 3px gray;
}
#eventTitleInput.error {
  border: 2px solid red;
}
#cancelButton, #deleteButton {
  background-color: #d36c6c;
}
#saveButton, #closeButton {
  background-color: #92a1d1;
}
#eventText {
  font-size: 14px;
}
#modalBackDrop {
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0,0,0,0.8);
}

.custom-modal-style {
  width: 1897px;
  max-width: none;
  height: 802px;
}

#images {
  margin-left: 1px;
}

#pagecontainer {
  max-width: 100vw;
  overflow: hidden; /* Hide scrollbars */
}

.viewmg {
  padding: 1em;
}

#rcorners-img {
  border-radius: 25px;
  border: 2px solid #73AD21;
  padding: 9px;
  width: 32vw;
  height: 28vw;
}
/* https://stackoverflow.com/questions/30162103/change-flex-direction-on-wrap
 */

.flex-container {
    display: -webkit-flex;
    display: flex;
}

@media (max-width: 810px) {
    .flex-container {
        -webkit-flex-direction: column;
                flex-direction: column;  
        -webkit-flex: 1 1;  
                flex: 1 1;    
    }
}

.flex-child {
    -webkit-flex: 1 1;
            flex: 1 1;
    border: 2px solid yellow;
}  

.flex-child:first-child {
    margin-right: 20px;
} 





.float-container {
    border: 3px solid #fff;
    padding: 20px;
}

.float-child {
    width: 50%;
    float: left;
    padding: 20px;
    border: 2px solid red;
}  






.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 576px) {
      .container {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .container {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .container {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .container {
        max-width: 1140px; } }


.row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  
.no-gutters {
    margin-right: 0;
    margin-left: 0; }
    .no-gutters > .col,
    .no-gutters > [class*="col-"] {
        padding-right: 0;
        padding-left: 0; }

.wrapper {
    width: 100%; }
  
.contact-wrap {
    background: #e8edf0; }

.dbox {
    width: 100%;
    margin-bottom: 25px;
    padding: 0 20px; }
    @media (min-width: 768px) {
        .dbox {
        margin-bottom: 0;
        padding: 0; } }
    .dbox p {
        margin-bottom: 0; }
        .dbox p span {
        font-weight: 500;
        color: #000; }
        .dbox p a {
        color: #2553b8; }
    .dbox .icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #2553b8;
        margin: 0 auto;
        margin-bottom: 20px; }
        .dbox .icon span {
        font-size: 20px;
        color: #fff; }
    .dbox .text {
        width: 100%;  }


/* :root{
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
}
 */
/* base styles & title */
/* body{
  font-family: "Noto Serif";
  color: var(--secondary);
}
.App{
  max-width: 960px;
  margin: 0 auto;
}
.title h1{
  color: var(--primary);
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
}
.title h2, .title p{
  text-align: center;
}
.title h2{
  margin-top: 60px;
  font-size: 2.6rem;
}
 */
/* upload form styles */
/* form{
  margin: 30px auto 10px;
  text-align: center;
}
label input{
  height: 0;
  width: 0;
  opacity: 0;
}
label{
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: var(--primary);
  font-weight: bold;
  font-size: 24px;
}
label:hover{
  background: var(--primary);
  color: white;
}
.output{
  height: 60px;
  font-size: 0.8rem;
}
.error{
  color: var(--error);
}
 */
/* progress bar styles */
/* .progress-bar{
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}
 */
/* image grid styles */
.img-grid{
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
/*   grid-template-columns: 1fr;
 */  grid-gap: 40px;
}
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
#townhall-center {
  height: 90vh;
}

#filescreen, #filemodal {
  display: none;
  position: fixed;
  overflow-y: auto;
}

#filescreen {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.25);
  z-index: 1;
}

#filemodal {
  overflow-x: hidden;
  width: 396px;
  top: 16%;
  max-height: 80vh;
  margin: 0 calc(1%);
  background-color:rgba(168, 129, 93, 0.8);
  z-index: 2;
  font-size: 16px;
  box-shadow: 0 0 20px rgba(0,0,0,0.15);
  border: 10px solid rgba(0,0,0,0.25);
  border-radius: 18px;
}

.disable-Selection {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}

#my-file-div {
  display: none;
  position: absolute;
  overflow-x: hidden;

  resize: both;

  width: 420px;
  top: 10%;
  max-height: 80vh;

  z-index: 9;
  background-color:rgba(168, 129, 93, 0.8);
  text-align: center;
  font-size: 16px;
  box-shadow: 0 0 20px rgba(0,0,0,0.15);
  border: 10px solid rgba(0,0,0,0.25);
  border-radius: 18px;
}
#my-file-div-test {
  display: none;
  position: absolute;
  overflow-x: hidden;

  resize: both;
  
  width: 420px;
  top: 10%;
  max-height: 80vh;

  z-index: 9;
  background-color:rgba(168, 129, 93, 0.8);
  text-align: center;
  font-size: 16px;
  box-shadow: 0 0 20px rgba(0,0,0,0.15);
  border: 10px solid rgba(0,0,0,0.25);
  border-radius: 18px;
}

.my-file-div-cloud {
    padding-left: 20px;
    margin-top: 10px;
    color: #fff;
    font-size: 25px;
}
input, select, textarea {
    font-size: 25px;
}   
.my-file-div-listing {
    overflow-y: scroll;
}
.my-file-div-delete {
    background: rgb(112, 32, 32);
    margin-left: 22px;
    border: none;
    color: #fff;
    font-size: 17px;
    border-radius: 13px;
    cursor: pointer;
}
#my-file-div-close1 {
  float: right;
  cursor: pointer;
  background: #555;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 13px;
  padding: 6px;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 16px;
}
#my-file-div-new1 {
  float: left;
  cursor: pointer;
  background: #555;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
  margin-top: 8px;
  border-radius: 13px;
  padding: 6px;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 16px;
}
#my-file-div-prev-notes1 {
  float: right;
  cursor: pointer;
  background: #555;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
  margin-top: 8px;
  border-radius: 13px;
  padding: 6px;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 16px;
}
#my-file-divheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196F3;
  color: #fff;
}
#my-file-div-title {
  padding: 10px;
  z-index: 10;
  color: #fff;
}
#my-file-div-title p {
  padding: 10px;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  color: blue;
  font-size: 20px;
}
#proto-card {
  display: none;
}

/* // https://stackoverflow.com/questions/20783975/make-span-visible-on-hover-css/20784195
 */

#myChart{
  background-color: black;
}
#tooltip {
  display: none;
  position: relative;
  top: 8px; 
  left: 10px;
  padding: 5px; 
  margin: 10px;
  z-index: 100;
  background: rgb(224, 187, 187);
  border: 1px solid #c0c0c0;
  opacity: 0.8; 
  width: 300px;
  color: black;
  text-align: left;
  
}

#graphs:hover #tooltip{
   display: block;   
}

#outPopUp:hover #tooltip{
  display: block;   
}

#graphs{
background-color: red;   
}

#outPopUp {
  position: absolute;
  width: 350px;
  height: 200px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
  background: red;
}
#outer-section {
  height:300px;
}
#code-input-str {
  display: inline-block;
  margin-top: 8px;
  font-size: 98%;
  height: 40px;
}
#code-input {
  margin-top: 14px;
  font-size: 95%;
  min-width:130px;
  max-width:220px;
}
#code-input2 {
  margin-top: 1px;
  font-size: 95%;
}

#one-span {
  display: inline-block;
}


#notes-btn1 {
  float: right;
}


#container {
  max-width: 96vw;
  padding: 0;
  float: left;
}

#status-msg {
  position: absolute;
  padding-top: 7px;
  top: 0;
}
@media only screen and (max-width: 1000px) {
  #status-msg {
    display: none;
  }
}

#acct {
  position: absolute;
  right: 0;
  padding-top: 7px;
  top: 0;
}


#move-container {
  display: -webkit-flex;
  display: flex;
}

#move-container > div {
  border: 1px solid gray;
  padding: 1em;

  height: 10em;
  width: 50%;
}

#movable-element {
  border: 1px solid green;
  background-color: #00ff0033;
  height: 100%;
  width: 100%;
}
input[type=checkbox]
{
  /* Double-sized Checkboxes */ /* IE */ /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */ /* Opera */
  transform: scale(2);
  padding: 10px;
  margin-right: 15px;
}

/* Might want to wrap a span around your checkbox text */
.checkboxtext
{
  /* Checkbox text */
  font-size: 155%;
  display: inline;
}

#innerbox
{
  width:250px; /* or whatever width you want. */
  max-width:250px; /* or whatever width you want. */
  height:250px; /* or whatever width you want. */
  max-height:250px; /* or whatever width you want. */
  display: inline-block;
}
/* * {
  margin: 0px;
  padding: 0px;
}
.container {
  display: flex;
  justify-content: center;
  margin: 60px 0;
}

*/

#img-profile {
  /*ADDED*/
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
#img-right {
  /*ADDED*/
  margin-left: 20px;
}
#img-left {
  /*ADDED*/
  margin-right: 20px;
}
.box3 {
  width: 300px;
  border-radius: 15px;
  background: #00bfb6;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-weight: 900;
  font-family: arial;
  position: relative;
}
/* right bubble */

.right:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid #00bfb6;
  border-right: 15px solid transparent;
  border-top: 15px solid #00bfb6;
  border-bottom: 15px solid transparent;
  right: -16px;
  top: 0px;
}
/* left bubble */

.left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid #00bfb6;
  border-top: 15px solid #00bfb6;
  border-bottom: 15px solid transparent;
  left: -16px;
  top: 0px;
}
.conversation-search {
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.conversation-search-input {
  background: #f4f4f8;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}

.conversation-search-input::-webkit-input-placeholder {
  text-align: center;
}

.conversation-search-input:-ms-input-placeholder {
  text-align: center;
}

.conversation-search-input::placeholder {
  text-align: center;
}

.conversation-search-input:focus::-webkit-input-placeholder {
  text-align: left;
}

.conversation-search-input:focus:-ms-input-placeholder {
  text-align: left;
}

.conversation-search-input:focus::placeholder {
  text-align: left;
}
.conversation-list-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}
.toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;

  position: -webkit-sticky;

  position: sticky;
  top: 0px;
}

.prev-hidden { display:none; }

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
}

.ck-editor__editable_inline {
  min-height: 240px;
}

.toolbar-title {
  margin-top: 6px;
  margin-left: 6px;
  font-size: 16px;
  font-weight: 800;
}

.left-items, .right-items {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
}

.right-items {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}
.toolbar-button {
  color: #007aff;
  font-size: 28px;
  transition: all 0.1s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #0063ce;
}

.toolbar-button:active {
  color: #007aff;
  opacity: 0.25;
}
.conversation-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.wrapper {
  position: relative;
  display: inline-block;
}
.xdelete {
  position: absolute;
  bottom: 0;
  left: 10px;
  cursor: pointer;
}
.compose {
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: fixed;
/*   width: 70vw;
 */
  width: calc(99% - 350px);
  bottom: 0px;
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
}

.compose-input {
  -webkit-flex: 1 1;
          flex: 1 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
}

.compose-input::-webkit-input-placeholder {
  opacity: 0.3;
}

.compose-input:-ms-input-placeholder {
  opacity: 0.3;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}
.message {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.message .timestamp {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: -webkit-flex;
  display: flex;
}

.message.mine .bubble-container {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

#img-profile {cursor: pointer;}
.message-list-container {
  padding: 10px;
  padding-bottom: 70px;
}

.messenger-panel-show {
  display: grid;
  width: 100%;
  height: 100vh;
/*   height: calc(100%-20px);
 */  background: #eeeef1;
 
  grid-template-columns: 388px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.messenger-panel-hide {
  display: grid;
  width: 100vw;
  height: 100vh;
/*   height: calc(100%-20px);
 */  background: #eeeef1;
 
  grid-template-columns: 50px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  height: calc(100vh - 10em);
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}
.toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;

  position: -webkit-sticky;

  position: sticky;
  top: 0px;
}

.prev-hidden { display:none; }

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
}

.ck-editor__editable_inline {
  min-height: 240px;
}

.toolbar-title {
  margin-top: 6px;
  margin-left: 6px;
  font-size: 16px;
  font-weight: 800;
}

.left-items, .right-items {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
}

.right-items {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}
.conversation-search {
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.conversation-search-input {
  background: #f4f4f8;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}

.conversation-search-input::-webkit-input-placeholder {
  text-align: center;
}

.conversation-search-input:-ms-input-placeholder {
  text-align: center;
}

.conversation-search-input::placeholder {
  text-align: center;
}

.conversation-search-input:focus::-webkit-input-placeholder {
  text-align: left;
}

.conversation-search-input:focus:-ms-input-placeholder {
  text-align: left;
}

.conversation-search-input:focus::placeholder {
  text-align: left;
}
.conversation-list-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}
.toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;

  position: -webkit-sticky;

  position: sticky;
  top: 0px;
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
}

.toolbar-title {
  margin-top: 6px;
  margin-left: 6px;
  font-size: 16px;
  font-weight: 800;
}

.left-items, .right-items {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
}

.right-items {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}
.toolbar-button {
  color: #007aff;
  font-size: 28px;
  transition: all 0.1s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #0063ce;
}

.toolbar-button:active {
  color: #007aff;
  opacity: 0.25;
}
.conversation-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.wrapper {
  position: relative;
  display: inline-block;
}
.xclose {
  position: absolute;
  bottom: 0;
  right: 10px;
  cursor: pointer;
}
.xdelete {
  position: absolute;
  bottom: 0;
  left: 10px;
  cursor: pointer;
}
.xassign {
  position: absolute;
  bottom: 0;
  left: 10px;
  cursor: pointer;
}
.compose {
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: fixed;
/*   width: 70vw;
 */
  width: calc(99% - 350px);
  bottom: 0px;
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
}

.compose-input {
  -webkit-flex: 1 1;
          flex: 1 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
}

.compose-input::-webkit-input-placeholder {
  opacity: 0.3;
}

.compose-input:-ms-input-placeholder {
  opacity: 0.3;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}
.message {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.message .timestamp {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: -webkit-flex;
  display: flex;
}

.message.mine .bubble-container {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.message-list-container {
  padding: 10px;
  padding-bottom: 70px;
}

.messenger-panel-show {
  display: grid;
  width: 100%;
  height: 100vh;
/*   height: calc(100%-20px);
 */  background: #eeeef1;
 
  grid-template-columns: 388px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.messenger-panel-hide {
  display: grid;
  width: 100vw;
  height: 100vh;
/*   height: calc(100%-20px);
 */  background: #eeeef1;
 
  grid-template-columns: 50px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  height: calc(100vh - 10em);
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}
#prof-images {
  margin-left: 1px;
}

#prof-pagecontainer {
  max-width: 100vw;
  overflow: hidden; /* Hide scrollbars */
}

.prof-viewmg {
  padding: 6em;
}

#prof-rcorners-img {
  border-radius: 120px;
  border: 2px solid #73AD21;
  padding: 9px;
  width: 240px;
  height: auto;
}
input[type=checkbox]
{
  /* Double-sized Checkboxes */ /* IE */ /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */ /* Opera */
  transform: scale(2);
  padding: 10px;
  margin-right: 15px;
}

/* Might want to wrap a span around your checkbox text */
.checkboxtext
{
  /* Checkbox text */
  font-size: 155%;
  display: inline;
}

.messenger-panel-show {
  display: grid;
  width: 100%;
  height: 100vh;
/*   height: calc(100%-20px);
 */  background: #eeeef1;
 
  grid-template-columns: 388px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.messenger-panel-hide {
  display: grid;
  width: 100vw;
  height: 100vh;
/*   height: calc(100%-20px);
 */  background: #eeeef1;
 
  grid-template-columns: 50px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  height: calc(100vh - 10em);
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}
