/* https://stackoverflow.com/questions/30162103/change-flex-direction-on-wrap
 */

.flex-container {
    display: flex;
}

@media (max-width: 810px) {
    .flex-container {
        flex-direction: column;  
        flex: 1;    
    }
}

.flex-child {
    flex: 1;
    border: 2px solid yellow;
}  

.flex-child:first-child {
    margin-right: 20px;
} 





.float-container {
    border: 3px solid #fff;
    padding: 20px;
}

.float-child {
    width: 50%;
    float: left;
    padding: 20px;
    border: 2px solid red;
}  






.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 576px) {
      .container {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .container {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .container {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .container {
        max-width: 1140px; } }


.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  
.no-gutters {
    margin-right: 0;
    margin-left: 0; }
    .no-gutters > .col,
    .no-gutters > [class*="col-"] {
        padding-right: 0;
        padding-left: 0; }

.wrapper {
    width: 100%; }
  
.contact-wrap {
    background: #e8edf0; }

.dbox {
    width: 100%;
    margin-bottom: 25px;
    padding: 0 20px; }
    @media (min-width: 768px) {
        .dbox {
        margin-bottom: 0;
        padding: 0; } }
    .dbox p {
        margin-bottom: 0; }
        .dbox p span {
        font-weight: 500;
        color: #000; }
        .dbox p a {
        color: #2553b8; }
    .dbox .icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #2553b8;
        margin: 0 auto;
        margin-bottom: 20px; }
        .dbox .icon span {
        font-size: 20px;
        color: #fff; }
    .dbox .text {
        width: 100%;  }
