#images {
  margin-left: 1px;
}

#pagecontainer {
  max-width: 100vw;
  overflow: hidden; /* Hide scrollbars */
}

.viewmg {
  padding: 1em;
}

#rcorners-img {
  border-radius: 25px;
  border: 2px solid #73AD21;
  padding: 9px;
  width: 32vw;
  height: 28vw;
}